import SessionFields from '~/sessions/session-fields.vue';
import ItemSearchSelector from '~/components/item-search-selector.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from '@mdi/js';
export default {
    name: 'participation-fields',
    components: {
        SessionFields,
        ItemSearchSelector,
        ValidationObserver,
        ValidationProvider,
    },
    model: {
        prop: 'participation',
    },
    props: {
        participation: {
            type: Object,
        },
        presetTraining: String,
        readOnly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            mdiPlusCircleOutline,
            mdiMinusCircleOutline,
        };
    },
};
