import DateTimeField from '~/components/date-time-field.vue';
import ItemSearchSelector from '~/components/item-search-selector.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CreditYearMixin from '~/mixins/creditYear';
export default {
    name: 'session-fields',
    mixins: [CreditYearMixin],
    components: {
        DateTimeField,
        ItemSearchSelector,
        ValidationObserver,
        ValidationProvider,
    },
    model: {
        prop: 'session',
    },
    props: {
        session: Object,
        presetTraining: String,
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        session: {
            handler(session) {
                if (!session.training && this.presetTraining) {
                    this.$set(this.session, 'training', this.presetTraining);
                }
                if (!session.date) {
                    this.$set(this.session, 'date', this.selectedCreditYearRange.start);
                }
            },
            immediate: true,
        },
    },
};
