var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.participation)?_c('div',{staticClass:"participation-fields"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"service",attrs:{"name":"service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('item-search-selector',{attrs:{"name":"service","resources":['services'],"label":_vm.$tc('services.title', 1),"item-text":function (item) { return ("[" + (item.reference) + "] " + (item.name)); },"error-messages":errors,"disabled":_vm.readOnly,"hide-details":"","no-filter":"","clearable":"","outlined":"","dense":""},on:{"input":function($event){return validate()}},model:{value:(_vm.participation.service),callback:function ($$v) {_vm.$set(_vm.participation, "service", $$v)},expression:"participation.service"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('session-fields',{attrs:{"preset-training":_vm.presetTraining,"readOnly":_vm.readOnly},model:{value:(_vm.participation.session),callback:function ($$v) {_vm.$set(_vm.participation, "session", $$v)},expression:"participation.session"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('ValidationProvider',{ref:"present",attrs:{"name":"present","rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"text-center",attrs:{"label":_vm.$t('participation.present'),"append-icon":_vm.mdiPlusCircleOutline,"prepend-inner-icon":_vm.mdiMinusCircleOutline,"error-messages":errors,"disabled":_vm.readOnly,"outlined":""},on:{"input":validate,"click:append":function($event){_vm.participation.present++},"click:prepend-inner":function($event){_vm.participation.present--},"focus":function($event){return $event.target.select()}},model:{value:(_vm.participation.present),callback:function ($$v) {_vm.$set(_vm.participation, "present", $$v)},expression:"participation.present"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{ref:"excused",attrs:{"name":"excused","rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"text-center",attrs:{"label":_vm.$t('participation.excused'),"append-icon":_vm.mdiPlusCircleOutline,"prepend-inner-icon":_vm.mdiMinusCircleOutline,"error-messages":errors,"disabled":_vm.readOnly,"outlined":""},on:{"input":validate,"click:append":function($event){_vm.participation.excused++},"click:prepend-inner":function($event){_vm.participation.excused--},"focus":function($event){return $event.target.select()}},model:{value:(_vm.participation.excused),callback:function ($$v) {_vm.$set(_vm.participation, "excused", $$v)},expression:"participation.excused"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{ref:"absent",attrs:{"name":"absent","rules":"required|integer|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"text-center",attrs:{"label":_vm.$t('participation.absent'),"append-icon":_vm.mdiPlusCircleOutline,"prepend-inner-icon":_vm.mdiMinusCircleOutline,"error-messages":errors,"disabled":_vm.readOnly,"outlined":""},on:{"input":validate,"click:append":function($event){_vm.participation.absent++},"click:prepend-inner":function($event){_vm.participation.absent--},"focus":function($event){return $event.target.select()}},model:{value:(_vm.participation.absent),callback:function ($$v) {_vm.$set(_vm.participation, "absent", $$v)},expression:"participation.absent"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('Notes'),"disabled":_vm.readOnly,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.participation.notes),callback:function ($$v) {_vm.$set(_vm.participation, "notes", $$v)},expression:"participation.notes"}})],1)],1)]}}],null,false,3112551338)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }