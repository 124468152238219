import λ from '~/utils/invoker';
import FormMixin from '~/mixins/form';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import PermissionMixin from '~/mixins/permission';
import CreditYearMixin from '~/mixins/creditYear';
import ParticipationFields from '~/participations/participation-fields.vue';
import { ValidationObserver } from 'vee-validate';
import { mdiContentSaveOutline } from '@mdi/js';
import { createParticipation } from '#/participation';
const participationsλ = λ.namespaced('participations');
export default {
    name: 'participations-form',
    mixins: [FormMixin('participations'), CreditYearMixin, PermissionMixin],
    components: {
        ValidationObserver,
        GoBackWrapper,
        ParticipationFields,
    },
    props: {
        id: {
            type: String,
        },
        service: String,
        training: String,
    },
    data() {
        return {
            mdiContentSaveOutline,
        };
    },
    async created() {
        await this.prepareForm(this.id);
    },
    computed: {
        item: participationsλ.state('editing'),
        canSave() {
            return this.canUpdateParticipations;
        },
        goBackTo() {
            var _a;
            return ((_a = this.$route.params) === null || _a === void 0 ? void 0 : _a.goBackTo) || '/participations';
        },
    },
    methods: {
        createNew() {
            return createParticipation({
                service: this.service || null,
                session: { date: this.selectedCreditYearRange.start },
            });
        },
    },
};
